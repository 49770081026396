import React from 'react'
import { faChevronRight, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from 'next/link'

import Image from '../Image'

import styles from './InfoItem.module.scss'

const InfoItem = ({
  title,
  description,
  icon,
  iconColour,
  linkTitle,
  link,
  external,
  image,
  backgroundColour
}) => {
  return (
    <div className={styles.infoItem} style={{
      background: backgroundColour || '#ffffff'
    }}>
      <div>
        {image ? (
          <div className={styles.infoItemImage}>
            <Image src={image} layout="fill" objectFit="contain" objectPosition="left center" alt="" />
          </div>
        ) : (
          <div className={styles.infoItemIcon} style={{
            background: iconColour || '#D11A6D'
          }}>
            <FontAwesomeIcon icon={icon || faHandHoldingHeart} />
          </div>
        )}
        <div className={styles.infoItemTitle}>{title}</div>
        <div className={styles.infoItemDescription}>{description}</div>
      </div>
      <Link href={link || '/'}>
        <a className={styles.infoItemLink} {...(external ? { target: '_blank', rel: 'noreferrer' } : {})}>
          <div>{linkTitle || 'learn more'}</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </Link>
    </div>
  )
}

export default InfoItem