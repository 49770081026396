import React from 'react'

import Image from '../Image'

import styles from './Partners.module.scss'

import gamcareLogo from '../../public/gamcare-logo.svg'
import ngtsLogo from '../../public/ngts-logo.png'
import bgaLogo from '../../public/bga-logo.png'

const Partners = ({
 showTitle,
 title,
 partners = [],
 backgroundColour
}) => {
  return (
    <div className={styles.partners} style={{
      background: backgroundColour || '#F1F1F1'
    }}>
      {showTitle &&
        <div className={styles.partnersTitle}>{title || 'Our Partners'}</div>
      }
      <div className={styles.partnersLogos}>
        {(partners || []).length > 0 ? (
          partners.map((p, pIdx) => (
            <div key={pIdx} className={styles.partnersLogo}>
              {p.noLink ? (
                <Image src={p.src} width={p.width} height={p.height} alt={p.alt} />
              ) : (
                <a href={p.href} target={p.noTarget ? '_self' : '_blank'} rel="noreferrer">
                  <Image src={p.src} width={p.width} height={p.height} alt={p.alt} />
                </a>
              )}
            </div>
          ))
        ) : (
          <>
            <div className={styles.partnersLogo}>
              <a href="https://www.gambleaware.org/ngsn" target="_blank" rel="noreferrer">
                <Image src={ngtsLogo} width={81} height={80} alt="NGTS" />
              </a>
            </div>
            {/*<div className={styles.partnersLogo}>
              <a href="http://www.gamcare.org.uk" target="_blank" rel="noreferrer">
                <Image src={gamcareLogo} width={210} height={60} alt="Gamcare" />
              </a>
            </div>*/}
            <div className={styles.partnersLogo}>
              <a href="https://www.gambleaware.org" target="_blank" rel="noreferrer">
                <Image src={bgaLogo} width={264} height={35} alt="GambleAware" objectFit="contain" />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Partners