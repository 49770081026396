import { faComment, faEnvelope, faHandHoldingHeart, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import Hero from '../components/Hero'
import Partners from '../components/Partners'
import ItemSection from '../components/ItemSection'
import InfoItem from '../components/InfoItem'
import Testimonials from '../components/Testimonials'
import PageLayout from '../components/PageLayout'
import ReachOut from '../sections/ReachOut'
import PositiveStep from '../sections/PositiveStep'
import HereToHelp from '../sections/HereToHelp'

import Config from '../config'

import styles from '../styles/Home.module.css'

import heroImage from '../public/home-hero.jpg'
import useViewport from '../hooks/useViewport'

export default function Home() {
  const viewport = useViewport()

  return (
    <PageLayout
      showAmazon={false}
    >
      <Hero 
        title="Is Gambling affecting your life?"
        description={<span>If you are concerned about yourself or a loved one contact us now for <b style={{ color: '#D11A6D' }}>free</b> support.</span>}
        image={heroImage}
        buttons={[{
          title: viewport.width < 768 ? 'CALL NOW' : Config.phoneDisplay,
          icon: faPhoneAlt,
          link: `tel:${Config.phone}`,
          external: true
        }, {
          title: 'SELF REFER',
          icon: faComment,
          link: '/contact'
        }, {
          title: 'EMAIL NOW',
          icon: faEnvelope,
          link: `mailto:${Config.email}`,
          external: true
        }]}
      />
      <Partners />
      <ReachOut />
      <HereToHelp />
      <Testimonials />
      {/*<ItemSection 
        title="STEPS TO TAKE"
        description="Self help toolkit"
        items={[
          <InfoItem 
            key="self" 
            title="Self Assessment"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="recovery" 
            title="Recovery Toolkit"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#252627"
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="blocking" 
            title="Blocking Software"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#247B7B"
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="money" 
            title="Money Management"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#E2B95A"
            linkTitle="LEARN MORE"
          />
        ]}
      />*/}
    </PageLayout>
  )
}
