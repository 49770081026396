import React from 'react'

import styles from './ItemSection.module.scss'

const ItemSection = ({
  title,
  titleColour,
  description,
  items = [],
  backgroundColour,
  itemBackgroundColour,
  gutterHorizontal,
  gutterVertical,
  size = 4
}: {
  title,
  titleColour?: string
  description
  items
  backgroundColour?: string
  itemBackgroundColour?: string
  gutterHorizontal?: number
  gutterVertical?: number
  size?: number
}) => {
  return (
    <div className={[styles.itemSection, styles[`size-${size}`]].join(' ')} style={{
      background: backgroundColour || '#F1F1F1'
    }}>
      <div className={`${styles.itemSectionContainer} container`}>
        <div className={styles.itemSectionTitle} style={{
          color: titleColour || '#D11A6D'
        }}>{title}</div>
        <div className={styles.itemSectionDescription}>{description}</div>
        <div className={styles.itemSectionItems} style={{
          marginLeft: -(gutterHorizontal ? (gutterHorizontal / 2) : 30),
          marginRight: -(gutterHorizontal ? (gutterHorizontal / 2) : 30),
          marginTop: -(gutterVertical ? (gutterVertical / 2) : 30),
          marginBottom: -(gutterVertical ? (gutterVertical / 2) : 30)
        }}>
          {items.map((x, idx) => (
            <div key={idx} className={styles.itemSectionItem} style={{
              paddingLeft: (gutterHorizontal / 2) || 30,
              paddingRight: (gutterHorizontal / 2) || 30,
              paddingTop: (gutterVertical / 2) || 30,
              paddingBottom: (gutterVertical / 2) || 30
            }}>
              {x}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ItemSection