import React from 'react'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Button from '../Button'

import styles from './ContactItem.module.scss'

const ContactItem = ({
  title,
  description,
  buttonTitle,
  icon,
  link,
  externalLink
}: {
  title: string
  description: string
  buttonTitle: string
  icon?: IconProp
  link?: string
  externalLink?: boolean
}) => {
  return (
    <div className={styles.contactItem}>
      <div className={styles.contactItemIcon}>
        <FontAwesomeIcon icon={icon || faPhoneAlt} />
      </div>
      <div className={styles.contactItemTitle}>{title}</div>
      <div className={styles.contactItemDescription}>{description}</div>
      <Button title={buttonTitle} icon={icon} link={link} external={externalLink} className={styles.button} />
    </div>
  )
}

export default ContactItem