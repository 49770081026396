import React, { useState, useRef, useEffect } from 'react'

import Image from '../Image'

import styles from './Testimonials.module.scss'

const testimonialData = [{
  quote: 'I wish I had taken this step years ago. This was the turning point in my life for me and I’m eternally grateful to you for the help and support you have given me.',
  name: '',
  description: '',
  image: ''
}, {
  quote: 'On Tuesday it will be six months since I last gambled in any way whatsoever. There was a time when this landmark would’ve meant something thing to me and I would’ve used terms like ‘clean’ or ‘one day at a time’. Not so much now though. Now I barely think about it at all and the only reason it’s on my mind was overhearing a mess room conversation earlier where two blokes were talking about their gambling habits. It genuinely made me feel sad for them and I had yet another moment where I thought ‘oh yeah! I don’t do that anymore do I?’.',
  name: '',
  description: '',
  image: ''
}, {
  quote: 'I’ll not get complacent or pretend I’m all fixed up but I’m the best I’ve been in decades.',
  name: '',
  description: '',
  image: ''
}, {
  quote: 'I’m a work in progress, still bad with money (and a few other things) but each issue will be sorted in time. Gambling had to be number one and now it’s time to move on.',
  name: '',
  description: '',
  image: ''
}, {
  quote: 'The support from my wife has been more than any man deserves and I just don’t know how to express what she means to me. The work I did with a counsellor/therapist was life changing and I hope I can one day repay a tiny part of what  I feel I owe her and breakeven as an organisation by sharing my ‘lived experience’ at a seminar in the future’.',
  name: '',
  description: '',
  image: ''
}]

const Testimonials = ({
  backgroundColour,
  itemBackgroundColour,
  data
}) => {
  const [current, setCurrent] = useState(0)
  const [isChanging, setIsChanging] = useState(false)

  const currentRef = useRef(0)
  const intervalRef = useRef()
  
  useEffect(() => {
    startAutoChange()

    return () => {
      stopAutoChange()
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsChanging(true)
    }, 10)

    setTimeout(() => {
      setIsChanging(false)

      currentRef.current = current
    }, 300)
  }, [current])

  const startAutoChange = () => {
    intervalRef.current = setInterval(() => {
      goToNext()
    }, 10000)
  }

  const stopAutoChange = () => {
    clearInterval(intervalRef.current)
  }

  const goToNext = () => {
    setCurrent(prev => {
      if (prev < ((data || testimonialData).length - 1)) {
        return prev + 1
      }

      return 0
    })
  }

  const goToPrev = () => {
    setCurrent(prev => {
      if (prev > 0) {
        return prev - 1
      }

      return 0
    })
  }

  const handleOnChange = newIdx => {
    setCurrent(newIdx)

    stopAutoChange()
    startAutoChange()
  }

  return (
    <div className={styles.testimonials} style={{
      background: backgroundColour || '#FFFFFF'
    }}>
      <div className="container">
        <div className={styles.testimonialsContainer} style={{
          background: itemBackgroundColour || '#F1F1F1'
        }}>
          <div className={styles.testimonialsCurrentItem}>
            <TestimonialItem data={(data || testimonialData)[current]} active={current} dotCount={(data || testimonialData).length} onChange={handleOnChange} />
          </div>
          {current !== currentRef.current &&
            <div className={[styles.testimonialsPrevItem, isChanging ? styles.testimonialItemChanging : ''].join(' ')}>
              <TestimonialItem data={(data || testimonialData)[currentRef.current]} active={current} onChange={handleOnChange} />
            </div>
            }
        </div>
      </div>
    </div>
  )
}

const TestimonialItem = ({
  data,
  active,
  onNext,
  onPrev,
  onChange,
  dotCount
}) => {
  return (
    <div className={styles.testimonialItem}>
      <div className={styles.testimonialItemLeft}>
        <div className={styles.testimonialItemTitle}>TESTIMONIAL</div>
        <div className={styles.testimonialItemQuote}>“{data.quote}“</div>
        {data.name && data.name.length > 0 &&
          <div className={styles.testimonialItemName}>{data.name}</div>
        }
        {data.description && data.description.length > 0 &&
          <div className={styles.testimonialItemDescription}>{data.description}</div>
        }
        <div className={styles.testimonialItemDots}>
          {(new Array(dotCount)).fill(0).map((x, idx) => (
            <div key={idx} className={styles.testimonialItemDotContainer} onClick={() => onChange(idx)}>
              <div className={[styles.testimonialItemDot, idx === active ? styles.testimonialItemDotActive : ''].join(' ')} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Testimonials