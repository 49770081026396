import React from 'react'
import { faComment, faComments, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import ContactItem from '../../components/ContactItem'
import ItemSection from '../../components/ItemSection'

import Config from '../../config'
import useViewport from '../../hooks/useViewport'

export default function HereToHelp({
  backgroundColour
}: {
  backgroundColour?: string
}) {
  const viewport = useViewport()

  return (
    <ItemSection 
      title="CONTACT US"
      description="We are here to help"
      size={3}
      items={[
        <ContactItem 
          key="phone" 
          title="Pick up the phone"
          description="SPEAK TO A FRIENDLY VOICE"
          icon={faPhone}
          buttonTitle={viewport.width < 768 ? 'CALL NOW' : Config.phoneDisplay}
          link={`tel:${Config.phone}`}
          externalLink
        />,
        <ContactItem 
          key="contact" 
          title="Self Refer"
          description="FILL IN A SELF REFERRAL FORM"
          icon={faComment}
          buttonTitle="Contact Us"
          link="/contact"
        />,
        <ContactItem 
          key="email" 
          title="Send an email"
          description="ASK US A QUESTION"
          icon={faEnvelope}
          buttonTitle="EMAIL NOW"
          link={`mailto:${Config.email}`}
          externalLink
        />
      ]}
    />
  )
}