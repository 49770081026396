import React from 'react'

import InfoSection from '../../components/InfoSection'

export default function ReachOut({
  backgroundColour
}) {
  return (
    <InfoSection 
      title="HOW WE CAN HELP"
      description="At Breakeven our trained specialists can help you on your way to recovery."
      links={[{
        title: 'learn more',
        link: '/support'
      }, {
        title: 'our offices',
        link: '/offices'
      }]}
      backgroundColour={backgroundColour}
    />
  )
}